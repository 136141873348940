import types from './mutation-types';

export default {
  [types.UPDATE_BET_TICKER_LIST](state, data) {
    state.betTickerList = data;
  },
  [types.SET_BET_TICKER_LIST_LOADING](state, isLoading) {
    state.betTickerListLoading = isLoading;
  },
  [types.SET_ACTIVE_SIDEBAR_OPTION](state, sidebarOption) {
    state.activeSidebarOption = sidebarOption;
  },
  [types.SET_SELECTED_SEARCH_FIELD](state, field) {
    state.selectedSearchField = field;
  },
  [types.UPDATE_BET_TICKER_SEARCH](state, value) {
    state.betTickerListSearch = value;
  },
  [types.SET_BET_TICKER_CONFIGURATIONS](state, value) {
    state.betTickerConfigurations = value;
  },
  [types.SET_ALL_SPORTS_DISPLAY_MARKETS_CONFIGURATION](state, value) {
    state.allSportsMarketDisplayConfigurations = value;
  },
  [types.SELECT_BET_TICKER](state, value) {
    state.selectedBetTicker = value;
  },
  [types.SHOW_DISABLED_TICKERS](state, showDisabledTickers) {
    state.showDisabledTickers = showDisabledTickers;
  },
  [types.SET_ONLY_FLAGGED_BETS](state, onlyFlaggedBets) {
    state.onlyFlaggedBets = onlyFlaggedBets;
  },
  [types.UPDATE_LIABILITY_EVENTS](state, events) {
    state.liabilityEvents = events;
  },
  [types.SET_LIABILITY_EVENTS_LOADING](state, value) {
    state.liabilityEventsLoading = value;
  },
  [types.UPDATE_LIABILITY_FILTERS_META](state, { sports, competitions }) {
    if (!sports) {
      state.liabilityFiltersMeta.competitions = competitions;
      return;
    }
    state.liabilityFiltersMeta = {
      sports,
      competitions,
    };
  },
  [types.UPDATE_LIABILITY_EVENT](state, value) {
    state.liabilityEvent = value;
  },
  [types.SET_LIABILITY_EVENT_LOADING](state, value) {
    state.liabilityEventLoading = value;
  },
  [types.SET_LIABILITY_DISPLAY_MARKETS_CONFIGURATION](state, value) {
    state.liabilityEventMarketsDisplayConfiguration = value;
  },
  [types.SET_LIABILITY_EVENT_MARKETS](state, value) {
    state.liabilityMarketsObject = value;
  },
  [types.SET_LIABILITY_EVENT_SELECTIONS_BY_MARKET_ID](state, value) {
    state.liabilityMarketSelectionsObject = value;
  },
  [types.SET_LIABILITY_MARKET_GROUPS](state, value) {
    state.marketGroups = value;
  },
};
