import {
  isObject, ceil, reduce, orderBy,
  castArray, find, includes, isNil,
  filter as filterArray, parseInt,
  keys,
} from 'lodash';
import qs from 'query-string';
import router from '@/router';
import * as Model from '@/models';
import base64 from '@/utils/base64';
import sportIds from '@/services/helpers/sports';
import userRoles from '@/services/helpers/user-roles';

const { DPS_TRADER } = userRoles;
const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  SOCCER_ID,
  ULTIMATE_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} = sportIds;

const decodeAndParse = (value) => {
  try {
    return JSON.parse(base64.decode(value));
  } catch {
    return '';
  }
};

export default {
  latestRoute: (state) => state.latestRoute,
  columns: (state) => orderBy(reduce(
    state.columns,
    (fields, values, key) => [
      ...fields,
      {
        ...values,
        key,
      },
    ],
    [],
  ), 'order'),
  search: (state) => state.search,
  selectedRowSpacingOption: (state) => state.selectedRowSpacingOption,
  currentPage: () => parseInt(router.currentRoute.value.query.page || 1, 10),
  rowsPerPage: () => {
    const defaultRowsPerPage = includes(router.currentRoute.value.name, 'Mapping') ? '100' : '50';
    return parseInt(router.currentRoute.value.query.rowsPerPage || defaultRowsPerPage, 10);
  },
  totalRowsCount: (state) => state.totalRowsCount,
  lastPage: (state, getters) => ceil(state.totalRowsCount / getters.rowsPerPage) || 1,
  operatorsList: (state) => state.operatorsList,

  eventListFields: (state) => orderBy(reduce(
    state.columns,
    (fields, values, key) => [
      ...fields,
      {
        ...values,
        key,
      },
    ],
    [],
  ), 'order'),
  eventListFilters: (state) => reduce(
    state.columns,
    (fields, values, key) => {
      if (values.filterable === false) return fields;
      return [
        ...fields,
        {
          ...values,
          field: key,
          inputType: values.inputType || 'text',
          inputArgs: values.inputArgs || null,
        },
      ];
    },
    [],
  ),
  eventListAppliedFilters: (state) => {
    const path = router.currentRoute.value.fullPath;
    const parsedUrl = qs.parseUrl(path);
    const filterParams = castArray(parsedUrl.query?.filter || []);
    return reduce(
      filterParams,
      (validFilters, filterParam) => {
        const filter = decodeAndParse(filterParam);
        const model = state.columns[filter.field || ''];

        // Field validation
        if (!model) return validFilters;
        // Modifier validation
        if (isNil(filter.modifier)) return validFilters;
        if (!find(state.eventListFilterModifiers?.[model.inputType] || [], { value: filter.modifier })) return validFilters;
        // Value validation
        if (isNil(filter.value)) return validFilters;
        if (model.inputType === 'select' && !find(model.inputArgs, { value: filter.value[filter.value.length - 1] })) return validFilters;
        if (model.inputType === 'date' && !/^\d{4}-\d{2}-\d{2}$/.test(filter.value[0]) && !/^\d{4}-\d{2}-\d{2}$/.test(filter.value[1])) return validFilters;

        return [...validFilters, filter];
      },
      [],
    );
  },
  eventListFilterModifiers: (state) => state.eventListFilterModifiers,
  eventListAppliedSort: () => {
    const path = router.currentRoute.value.fullPath;
    const parsedUrl = qs.parseUrl(path);
    let appliedSort = parsedUrl.query?.sort;
    if (!appliedSort) {
      return {
        field: 'startsAt',
        order: 'ASC',
      };
    }
    appliedSort = decodeAndParse(appliedSort);
    return appliedSort;
  },
  previousEventListOptions: (state) => state.previousEventListOptions,
  eventList: (state) => state.eventList,
  eventListLoading: (state) => state.eventListLoading,
  bookedEvents: (state) => [
    ...reduce(
      state.eventList,
      (bookedEvents, event) => {
        if (!event.booked) return bookedEvents;
        return [...bookedEvents, event.eventId];
      },
      [],
    ),
    ...state.bookedEventsInSession,
  ],
  selectedEvents: (state) => state.selectedEvents,
  isEventsSidebarActive: (state) => state.isEventsSidebarActive,
  isEventsFiltersActive: (state) => state.isEventsFiltersActive,

  sidebarExpanded: (state) => state.sidebar.expanded,
  sidebarLoading: (state) => state.sidebar.loading,
  sidebarSports: (state) => state.sidebar.sports,
  sidebarRegions: (state) => state.sidebar.regions,
  sidebarRegionsBySportId: (state) => (sportId) => filterArray(state.sidebar.regions, { sportId }),
  sidebarCompetitions: (state) => state.sidebar.competitions,
  sidebarCompetitionsBySportIdAndRegionId: (state) => (sportId, regionId) => {
    if (!sportId && !regionId) return state.sidebar.competitions;
    const competitionsBySport = filterArray(state.sidebar.competitions, { sportId });
    if (!regionId) return competitionsBySport;
    return filterArray(competitionsBySport, { regionId });
  },
  sidebarLoadingIds: (state) => state.sidebar.loadingIds,
  sidebarLoadingCompetitions: (state) => (sportId, regionId) => includes(state.sidebar.loadingIds, sportId) && includes(state.sidebar.loadingIds, regionId),
  sidebarSelection: (state) => state.sidebar.selection,
  sidebarSportId: (state, getters) => getters.sidebarSelection?.sportId,
  sidebarRegionId: (state, getters) => getters.sidebarSelection?.regionId,
  sidebarCompetitionId: (state, getters) => getters.sidebarSelection?.competitionId,
  sidebarSubscribed: (state, getters) => getters.sidebarSelection?.subscribed,

  subscriptionModalOpen: (state) => state.subscriptionModal.open,
  subscriptionModalType: (state) => state.subscriptionModal.type,
  subscriptionModalId: (state) => state.subscriptionModal.id,

  event: (state) => {
    if (!isObject(state.event)) return null;
    if (state.event.alreadyMapped) return state.event;
    switch (state.event?.sportId) {
    case SOCCER_ID:
      return Model.SoccerEvent(state.event);
    case BASKETBALL_ID:
      return Model.BasketballEvent(state.event);
    case FOOTBALL_ID:
      return Model.FootballEvent(state.event);
    case ULTIMATE_ID:
      return Model.UltimateEvent(state.event);
    case HOCKEY_ID:
      return Model.HockeyEvent(state.event);
    case BASEBALL_ID:
      return Model.BaseballEvent(state.event);
    default:
      return Model.Event(state.event);
    }
  },
  eventLoading: (state) => state.eventLoading,
  eventLoaded: (state) => !state.eventLoading && state.event,
  eventMarketsLoading: (state) => state.eventMarketsLoading,
  eventMarketsLoaded: (state) => !state.eventMarketsLoading && state.eventMarkets,
  eventMarkets: (state, getters) => {
    if (
      !getters.eventLoaded
      || !getters.eventMarketsLoaded
      || !state.eventMarkets
    ) return null;

    if (getters.event.matchState === 'FINISHED') return [];
    const markets = getters.event.matchState === 'LIVE'
      ? filterArray(state.eventMarkets, { inPlay: true })
      : state.eventMarkets;
    return markets;
  },
  eventTeamPlayers: (state) => state.eventTeamPlayers,

  marketTemplate: (state) => state.marketTemplate,
  sportTemplates: (state) => state.sportTemplates,
  competitionTemplateChanged: (state) => state.competitionTemplateChanged,
  userToken: (state) => state.userToken,
  userRoles: (state) => state.userRoles,
  operator: (state) => state.operator,
  isDpsTraderRole: (state) => state.userRoles.includes(DPS_TRADER),
  loginErrorMessage: (state) => state.loginErrorMessage,
  changePasswordAttributes: (state) => state.changePasswordAttributes,

  allFeeds: (state) => state.allFeeds,

  notifications: (state) => state.notifications,
  suspendEventActionLoading: (state) => (eventId) => !!filterArray(state.suspensionInProgressEventIds, (stateEventId) => stateEventId === eventId)?.length,
  suspendMarketTypeActionLoading: (state) => state.suspendMarketTypeActionLoading,
  eventListMappingEvent: (state) => state.eventListMappingEvent,

  marketGroupsBySportId: (state) => (sportId) => filterArray(state.marketGroups,
    (group) => sportId === group.sportId && group.active),
  marketFavorites: (state) => state.marketFavorites,

  mappingInitialized: (state) => state.mapping.initialized,
  mappingLoading: (state) => state.mapping.loading,
  mappingSports: (state) => state.mapping.sports,
  mappingEntries: (state) => state.mapping.entries,
  mappingSelectedSportId: (state) => state.mapping.selectedSportId,
  mappingSelectedSportLabel: (state) => state.mapping.selectedSportLabel,
  mappingPrimaryFeeds: (state) => {
    const routeName = router.currentRoute.value?.name;
    const feeds = routeName === 'regionsMapping'
      ? state.mapping.primaryFeeds
      : filterArray(state.mapping.primaryFeeds, (feed) => includes(state.mapping.feedsPerSport, feed));
    return feeds;
  },
  mappingSecondaryFeeds: (state) => {
    const routeName = router.currentRoute.value?.name;
    const feeds = routeName === 'regionsMapping'
      ? filterArray(state.mapping.secondaryFeeds, (feed) => feed !== 'nba.com' && feed !== 'rotowire')
      : filterArray(state.mapping.secondaryFeeds, (feed) => includes(state.mapping.feedsPerSport, feed));
    return feeds;
  },
  mappingDisplayedFeeds: (state) => state.mapping.displayedFeeds,
  mappingSelectedFeed: (state) => state.mapping.selectedFeed,
  mappingSearchOptions: (state) => state.mapping.searchOptions,
  mappingSelectedSearchOption: (state) => state.mapping.selectedSearchOption,
  mappingSearchQuery: (state) => state.mapping.searchQuery,
  mappingSelectedDisplay: (state) => state.mapping.selectedDisplay,
  mappingDisplayOptions: (state) => state.mapping.displayOptions,
  mappingSelectedDate: (state) => state.mapping.selectedDate,
  mappingDateOptions: (state) => state.mapping.dateOptions,
  eventSuggestedMappings: (state) => state.eventSuggestedMappings,
  competitorSuggestedMappings: (state) => state.competitorSuggestedMappings,
  regionSuggestedMappings: (state) => state.regionSuggestedMappings,
  competitionSuggestedMappings: (state) => state.competitionSuggestedMappings,
  playerSuggestedMappings: (state) => state.playerSuggestedMappings,
  manualResultingEvent: (state) => state.manualResultingEvent,
  manualResultingEventLoading: (state) => state.manualResultingEventLoading,
  manualResultingEvents: (state) => state.manualResultingEvents,
  manualResultingEventMarkets: (state) => state.manualResultingEventMarkets,
  getManualResultingEventMarketById: (state) => (marketId) => state.manualResultingEventMarkets[marketId],
  manualResultingEventMarketsLoading: (state) => state.manualResultingEventMarketsLoading,
  // Player setup
  playerSetupTableData: (state) => state.playerSetupTableData,
  playerSetupOriginalTableData: (state) => state.playerSetupOriginalTableData,
  playerSetupData: (state) => state.playerSetupData,
  isPlayerSetupDataLoading: (state) => state.isPlayerSetupDataLoading,
  isPlayerSetupProjectionsAvailable: (state) => state.isPlayerSetupProjectionsAvailable,
  paramsManagerGameProjectionsAvailable: (state) => state.paramsManagerGameProjectionsAvailable,
  playerSetupDataLoaded: (state) => !state.isPlayerSetupDataLoading && state.playerSetupData,
  isPlayerSetupScoreboardActive: (state) => state.isPlayerSetupScoreboardActive,
  playerSetupEventList: (state) => state.playerSetupEventList,
  playerSetupEventListLoading: (state) => state.playerSetupEventListLoading,
  playerSetupEventSearchActive: (state) => state.playerSetupEventSearchActive,
  teamSquadListLoading: (state) => state.teamSquadListLoading,
  isPlayerSetupSubmitEnabled: (state) => state.isPlayerSetupSubmitEnabled,
  isPlayerSetupSimulateEnabled: (state) => state.isPlayerSetupSimulateEnabled,
  isPlayerSetupSimulateBySocketChangeEnabled: (state) => state.isPlayerSetupSimulateBySocketChangeEnabled,
  numberOfSimulateAttempts: (state) => state.numberOfSimulateAttempts,
  isPlayerSetupPageFrozen: (state) => state.isPlayerSetupPageFrozen,
  frozenPlayerSetupPageMessage: (state) => state.frozenPlayerSetupPageMessage,
  isPlayerSetupPageSimulateBtnFrozen: (state) => state.isPlayerSetupPageSimulateBtnFrozen,
  availableMarkets: (state) => state.availableMarkets,
  isMarketsLoading: (state) => state.isMarketsLoading,
  playersForMarketsPopupList: (state) => state.playersForMarketsPopupList,
  highlightedPlayer: (state) => state.highlightedPlayer,
  playerSetupInferActive: (state) => state.playerSetupInferActive,
  playerSetupBeforeInferState: (state) => state.playerSetupBeforeInferState,
  playerParamsSelectedMode: (state) => state.playerParamsSelectedMode,
  paramsManagerMarkets: (state) => state.paramsManagerMarkets,
  paramsManagerMarketsBeforeInferState: (state) => state.paramsManagerMarketsBeforeInferState,
  lineupPresetsByTeam: (state) => state.lineupPresetsByTeam,
  selectedLineupPresetByTeam: (state) => state.selectedLineupPresetByTeam,
  paramsManagerSquadData: (state) => state.paramsManagerSquadData,
  paramsManagerEventSuspendLoading: (state) => state.paramsManagerEventSuspendLoading,
  availableMarketsPlayerTypes: (state) => state.availableMarketsPlayerTypes,
  playerSetupUnpublishedChanges: (state) => state.playerSetupUnpublishedChanges,

  playerSetupMappedPlayersForProjections: (state) => state.playerSetupMappedPlayersForProjections,
  // Game params
  playerSetupGameParams: (state) => state.playerSetupGameParams,
  gameParamsSelectedMode: (state) => state.gameParamsSelectedMode,
  gameParamsLastSubmittedSelectedMode: (state) => state.gameParamsLastSubmittedSelectedMode,
  allBaseballParks: (state) => state.allBaseballParks,
  gameParamsSelectedMarkets: (state) => state.gameParamsSelectedMarkets,
  gameParamsSelectedMarketsProcessing: (state) => state.gameParamsSelectedMarketsProcessing,

  // Trading UI
  tradingEvent: (state) => state.tradingEvent,
  tradingEventLoading: (state) => state.tradingEventLoading,
  originalTradingPlayerProps: (state) => state.originalTradingPlayerProps,
  lastSimulateTradingPlayerProps: (state) => state.lastSimulateTradingPlayerProps,
  tradingPlayerProps: (state) => state.tradingPlayerProps,
  isSimulateBtnEnabled: (state) => state.isSimulateBtnEnabled,
  isSimulateBtnLoading: (state) => state.isSimulateBtnLoading,
  isPublishBtnEnabled: (state) => state.isPublishBtnEnabled,
  isPublishBtnLoading: (state) => state.isPublishBtnLoading,
  tradingPlayerPropsLoading: (state) => state.tradingPlayerPropsLoading,
  tradingEventList: (state) => state.tradingEventList,
  tradingEventListLoading: (state) => state.tradingEventListLoading,
  tradingEventSearchActive: (state) => state.tradingEventSearchActive,
  tradingEventMarkets: (state) => state.tradingEventMarkets,
  getTradingMarketById: (state) => (marketId) => state.tradingEventMarkets[marketId],
  tradingEventSuspendLoading: (state) => state.tradingEventSuspendLoading,
  selectedTradingMarkets: (state) => state.selectedTradingMarkets,
  isTradingUIPageFrozen: (state) => state.isTradingUIPageFrozen,
  isTradingUIPageSimulateBtnFrozen: (state) => state.isTradingUIPageSimulateBtnFrozen,
  isUnsavedChangesActive: (state) => state.isUnsavedChangesActive,
  tradingSquad: (state) => state.tradingSquad,
  tradingGameParamsLoading: (state) => state.tradingGameParamsLoading,
  tradingEventFeeds: (state) => state.tradingEventFeeds,
  tradingEventSelectedFeed: (state) => state.tradingEventSelectedFeed,
  tradingSimulateBySocketChangeEnabled: (state) => state.tradingSimulateBySocketChangeEnabled,

  oddFormats: (state) => state.oddFormats,
  selectedOddFormat: (state) => state.selectedOddFormat,

  // Manual edit
  manualEditSelectedSport: (state) => state.manualEditSelectedSport,
  manualEditTableColumns: (state) => orderBy(reduce(
    state.manualEditTableColumns,
    (fields, values, key) => [
      ...fields,
      {
        ...values,
        key,
      },
    ],
    [],
  ), 'order'),
  manualEditTableData: (state) => state.manualEditTableData,
  manualEditSelectedRow: (state) => state.manualEditSelectedRow,
  manualEditDataLoading: (state) => state.manualEditDataLoading,
  manualEditPlayerSearchList: (state) => state.manualEditPlayerSearchList,
  manualEditPlayerSearchListLoading: (state) => state.manualEditPlayerSearchListLoading,
  manualEditSearch: (state) => state.manualEditSearch,
  manualEditSelectedFeed: (state) => state.manualEditSelectedFeed,
  manualEditIsCreateNewRecordActive: (state) => state.manualEditIsCreateNewRecordActive,

  // market display configuration
  displayMarketsConfiguration: (state) => state.displayMarketsConfiguration || null,
  getSingleMarketDisplayConfiguration: (state) => (marketCode, selections) => state.displayMarketsConfiguration[`${marketCode}_${selections}`] || null,
  allMarketParameters: (state) => (sportLabel = '') => filterArray(state.allMarketParameters, (parameter) => parameter.sport === sportLabel),

  // Market group
  marketGroupMarketCodeSearchList: (state) => state.marketGroupMarketCodeSearchList,
  marketGroupMarketCodeSearchListLoading: (state) => state.marketGroupMarketCodeSearchListLoading,
  // Multiview
  multiviewListLatestOptions: (state) => state.multiviewList.latestOptions,
  multiviewListLoading: (state) => state.multiviewList.loading,
  multiviewListEventIds: (state) => keys(state.multiviewList.eventsById),
  multiviewListEventsById: (state) => state.multiviewList.eventsById,
  multiviewListCompetitionIds: (state) => keys(state.multiviewList.competitionsById),
  multiviewListCompetitionsById: (state) => state.multiviewList.competitionsById,
  getMultiviewListEventMarkets: (state) => (eventId) => filterArray(state.multiviewList.markets, (_, key) => key.split('_')[0] === eventId),
  getMultiviewListEventMarketsForSimulation: (state) => (eventId) => state.multiviewList?.eventsById[eventId]?.simulatedMarkets,
  getMultiviewListMarketById: (state) => (eventId, marketId) => state.multiviewList.markets[`${eventId}_${marketId}`],
  multiviewDrawerEvent: (state) => state.multiviewDrawer.event,
  multiviewDrawerLoading: (state) => state.multiviewDrawer.loading,
  multiviewDrawerProcessing: (state) => state.multiviewDrawer.processing,
  multiviewOriginalPlayerParams: (state) => state.multiviewOriginalPlayerParams,
  multiviewListParamsLoading: (state) => state.multiviewList.paramsLoading,
  getSingleMultiviewListEvent: (state) => (eventId) => state.multiviewList.eventsById[eventId] || {},

  // Multiview player params
  multiviewPlayerParams: (state) => state.multiviewPlayerParams,
  multiviewPlayerParamsLoading: (state) => state.multiviewPlayerParamsLoading,
  multiviewLastSimulatePlayerParams: (state) => state.multiviewLastSimulatePlayerParams,
  multiviewSquad: (state) => state.multiviewSquad,
  multiviewSimulateBtnEnabled: (state) => state.multiviewSimulateBtnEnabled,
  multiviewSimulateBtnFrozen: (state) => state.multiviewSimulateBtnFrozen,
  multiviewSimulateBtnLoading: (state) => state.multiviewSimulateBtnLoading,
  multiviewSubmitBtnEnabled: (state) => state.multiviewSubmitBtnEnabled,
  multiviewSubmitBtnLoading: (state) => state.multiviewSubmitBtnLoading,
  multiviewDrawerFrozen: (state) => state.multiviewDrawerFrozen,
  multiviewNumberOfSimulateAttempts: (state) => state.multiviewNumberOfSimulateAttempts,
  multiviewSimulateBySocketChangeEnabled: (state) => state.multiviewSimulateBySocketChangeEnabled,

  // Odds checker
  oddsCheckerSports: (state) => state.oddsCheckerFiltersMeta.sports,
  oddsCheckerCompetitions: (state) => state.oddsCheckerFiltersMeta.competitions,
  oddsCheckerEvents: (state) => state.oddsCheckerEvents,
  oddsCheckerEventById: (state) => (eventId) => state.oddsCheckerEvents[eventId],
  oddsCheckerEventsLoading: (state) => state.oddsCheckerEventsLoading,
  oddsCheckerQueryOptions: (state) => state.oddsCheckerQueryOptions,
};
