import { createStore } from 'vuex';
import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import betTickerModule from './modules/bet-ticker';

export default createStore({
  state,
  actions,
  mutations,
  getters,
  modules: {
    betTicker: {
      namespaced: true,
      ...betTickerModule,
    },
  },
});
