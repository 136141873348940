import { Base64 } from 'js-base64';

const encode = (value) => Base64.btoa(value);

const decode = (encodedValue) => Base64.atob(encodedValue);

export default {
  encode,
  decode,
};
