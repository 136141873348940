import {
  filter,
  orderBy,
  map,
  find,
} from 'lodash';
import {
  filterSupportedBetHighlights,
  mapMatchedHighlights,
  mapClashingHighlights,
} from '@/services/helpers/highlights';

export default {
  betTickerList: (state, getters, rootState, rootGetters) => mapMatchedHighlights(state.betTickerList, {
    rowHighlights: getters.betTickerRowHighlights,
    cellHighlights: getters.betTickerCellHighlights,
    oddsFormat: rootGetters.selectedOddFormat.name,
  }),
  betTickerListLoading: (state) => state.betTickerListLoading,
  betTickerListSearch: (state) => state.betTickerListSearch,
  activeSidebarOption: (state) => state.activeSidebarOption,
  selectedSearchField: (state) => state.selectedSearchField,
  betTickerConfigurations: (state) => state.betTickerConfigurations,
  betTickerHighlights: (state) => {
    const betTickerConfiguration = find(state.betTickerConfigurations, { betTickerId: state.selectedBetTicker?.betTickerId });
    if (!betTickerConfiguration) return [];
    const supportedHighlights = filterSupportedBetHighlights(betTickerConfiguration.highlights || []);
    const mappedHighlights = mapClashingHighlights(map(supportedHighlights, (highlight, index) => ({ ...highlight, index })));
    const indexedHighlights = map(mappedHighlights, (highlight, index) => ({ ...highlight, index }));
    return orderBy(indexedHighlights, 'index');
  },
  betTickerRowHighlights: (state, getters) => filter(getters.betTickerHighlights, { highlightType: 'ROW' }),
  betTickerCellHighlights: (state, getters) => filter(getters.betTickerHighlights, { highlightType: 'CELL' }),
  selectedBetTicker: (state) => state.selectedBetTicker,
  marketDisplayConfigurationByIds: (state) => ({ sportId, marketCode, selections }) => state.allSportsMarketDisplayConfigurations[`${sportId}_${marketCode}_${selections}`] || null,
  showDisabledTickers: (state) => state.showDisabledTickers,
  onlyFlaggedBets: (state) => state.onlyFlaggedBets,

  liabilityEvents: (state) => state.liabilityEvents?.events || [],
  liabilityEventsRowCount: (state) => state.liabilityEvents?.totalCount || 0,
  liabilityEventsLoading: (state) => state.liabilityEventsLoading,
  liabilitySports: (state) => state.liabilityFiltersMeta.sports,
  liabilityCompetitions: (state) => state.liabilityFiltersMeta.competitions,
  liabilityEvent: (state) => state.liabilityEvent,
  liabilityEventLoading: (state) => state.liabilityEventLoading,
  displayMarketsConfiguration: (state) => state.liabilityEventMarketsDisplayConfiguration || null,
  getSingleMarketDisplayConfiguration: (state) => (marketCode, selections) => state.liabilityEventMarketsDisplayConfiguration[`${marketCode}_${selections}`] || null,
  liabilityEventMarkets: (state) => state.liabilityMarketsObject,
  getLiabilityMarketById: (state) => (marketId) => state.liabilityMarketsObject[marketId],
  getLiabilityMarketSelectionsById: (state) => (marketId) => state.liabilityMarketSelectionsObject[marketId],
  marketGroupsBySportId: (state) => (sportId) => filter(state.marketGroups,
    (group) => sportId === group.sportId && group.active),
};
