export default {
  betTickerList: [],
  betTickerListLoading: false,
  betTickerListSearch: '',
  activeSidebarOption: null,
  selectedSearchField: 'eventName',
  betTickerConfigurations: [],
  allSportsMarketDisplayConfigurations: {},
  selectedBetTicker: null,
  showDisabledTickers: false,
  onlyFlaggedBets: false,

  liabilityEvents: [],
  liabilityEventsLoading: false,
  liabilityFiltersMeta: {
    sports: [],
    competitions: [],
  },
  liabilityEvent: null,
  liabilityEventLoading: false,
  liabilityEventMarketsDisplayConfiguration: null,
  liabilityMarketsObject: null,
  liabilityMarketSelectionsObject: null,
  marketGroups: [],
};
