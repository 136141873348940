import { format, zonedTimeToUtc } from 'date-fns-tz';

export const DateTypes = {
  DATE_TIME: 'dd.MM.yyyy. HH:mm',
  DATE_TIME_MAPPING: 'dd.MM.yyyy @ p',
  DATE_TIME_SUGGESTED_MAPPING: 'MMM dd, yyyy @ p',
  DATE: 'dd.MM.yyyy.',
  TIME: 'HH:mm',
  ISO_DATE: 'yyyy-MM-dd',
  ISO_DATE_TIME: 'yyyy-LL-dd\'T\'HH:mm:ss',
};

/**
 * Formats zoned date and/or time
 *
 * @param dateType Formatting pattern (e.g. dd.MM.yyyy. HH:mm)
 * @returns formatted zoned date and/or time, or an empty string.
 */
export default (value, dateType = DateTypes.DATE_TIME, alreadyUtc) => {
  const dateValue = alreadyUtc ? new Date(value) : zonedTimeToUtc(value, 'UTC');
  return !value ? '-' : format(dateValue, dateType);
};
